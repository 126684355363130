<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <div class="ml-auto mr-2">
      <ion-buttons slot="end">
        <ion-button size="medium" @click="dismissModal">
          <i class="ti-close" style="color: white; font-weight: bolder" />
        </ion-button>
      </ion-buttons>
    </div>
    <div
      class="modal-header d-flex flex-column justify-content-center align-items-center text-center"
      style="z-index: 1"
    >
      <span :class="userHasCharacters ? ' challenge-text' : 'character-text'">
        {{ userHasCharacters ? 'YOU DID IT' : 'CREATE A CHARACTER' }}
      </span>
      <span class="mx-1" :class="userHasCharacters ? 'character-text' : 'challenge-text'">
        {{ userHasCharacters ? 'CREATE A CHARACTER CHALLENGE' : 'CHALLENGE' }}
      </span>
    </div>

    <div class="modal-content-wrapper">
      <div class="modal-inner-content">
        <div class="d-flex flex-column text-center" style="padding: 16px">
          <OnboardingCharacterCreationChallengeCard
            :isChallengeModal="true"
            :isRewardClaim="isRewardClaim"
            :challengeEndTime="challengeEndTime"
            @items="fetchRewards"
            @load="loader"
          />
        </div>
        <ion-footer translucent>
          <ion-toolbar class="footer-toolbar">
            <div class="d-flex justify-content-center align-items-center mx-auto" v-if="isLoading">
              <ion-skeleton-text animated class="my-1" style="width: 50%; height: 20px" />
            </div>
            <div class="d-flex align-items-center justify-content-center" v-else>
              <ion-button class="close-btn" @click="dismissModal" v-if="isRewardClaim">Close</ion-button>
              <ion-button
                @click="claimRewards"
                :disabled="isLoading || isClaiming"
                class="claim-btn"
                v-else-if="userHasCharacters"
                >Claim Your Reward!</ion-button
              >
              <ion-button v-else @click="characterCreation" class="create-btn">
                <div><img src="/create_char_onboarding.png" height="18" width="18" /> Create Character</div>
              </ion-button>
            </div>
          </ion-toolbar>
        </ion-footer>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import OnboardingCharacterCreationChallengeCard from '../components/OnboardingCharacterCreationChallengeCard.vue';
import { toast } from '~/shared/native/toast';
import { authStore } from '../pinia-store/auth';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
});
const { userHasCharacters } = authStore();
const challengeEndTime: any = ref(null);
const { challengeEndsAt } = useOnboardingChallengeTimer();
const emits = defineEmits(['close']);
const isRewardClaim = ref(false);
const isOpen = toRef(props, 'isOpen');
const rewardItems: any = ref({});
const isLoading = ref(true);
const isClaiming = ref(false);
watch(
  isOpen,
  () => {
    if (isOpen.value) {
      challengeEndTime.value = challengeEndsAt();

      setTimeout(() => {
        challengeEndTime.value = 0;
      }, challengeEndTime.value);
    }
  },
  { immediate: true }
);

const reset = () => {
  rewardItems.value = {};
  isRewardClaim.value = false;
};
const dismissModal = () => {
  emits('close');
  setTimeout(() => {
    reset();
  }, 100);
};
const claimRewards = () => {
  isClaiming.value = true;
  if (isRewardClaim.value) return;
  isRewardClaim.value = true;
  toast.show('Claimed Rewards Successfully', 'nonative', 'success');
  isClaiming.value = false;
};
const fetchRewards = (items: any) => {
  rewardItems.value = items;
};
const loader = (val: boolean) => {
  isLoading.value = val;
};
const characterCreation = () => {
  const router = useRouter();
  router.push({ name: 'character-creator' });
};
</script>
<style lang="sass" scoped>
.claim-btn
  --background: linear-gradient(90deg, rgba(6, 247, 233, 0.7) 0%, rgba(6, 131, 247, 0) 100%), #00AAE0
  --border-radius: 11px
.create-btn
  --border-radius: 11px
  --background: linear-gradient(90deg, rgba(247, 6, 207, 0.7) 0%, rgba(247, 6, 207, 0) 100%), #AE38E5
.more-btn
  color: #ae38e5
  font-size: 16px
  font-weight: bolder
.character-text
  font: bolder 22px "Roboto Slab", sans-serif
  -webkit-text-fill-color: #431C89
.challenge-text
  font: bolder 45px "Roboto Slab", sans-serif
  -webkit-text-fill-color: #fff
.custom-modal
  display: flex
  flex-direction: column
  height: 100%
  background-color: #ffffff
  border-radius: 20px 20px 0 0
  overflow: hidden

.modal-header
  width: 100%
  background: linear-gradient(90deg, rgba(126, 224, 255, 0.4) 0%, rgba(95, 169, 238, 0) 100%), #A83CFF
  color: white
  padding: 15px
  border-radius: 20px

.modal-content-wrapper
  display: flex
  justify-content: center
  margin-top: -7px

.modal-content
  background-color: #ffffff
  width: 85%
  max-width: 400px
  border-radius: 12px
  padding: 16px
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2)
  text-align: center

.char-challenge-content
  width: 100%
  height: 100px
  background-color: rgb(174, 56, 229)
  border-radius: 20px
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  font: bolder 28px "Roboto Slab", sans-serif
  -webkit-text-fill-color: #ffffff
.toolbar
  --background: #9874DB !important
.dark .toolbar
  --background: #17074c !important
.footer-toolbar
  --background: #fff !important
.dark .footer-toolbar
  --background: #17074c !important
.modal-inner-content
  background: var(--ion-background-color, #f9f9ff)
  max-height: 70vh
  overflow: auto
  max-height: 70dvh
  background-color: #ffffff
  width: 88%
  max-width: 400px
  border-radius: 12px
  text-align: center

.modal-big
  --width: 450px
  --background: #00000033
  --background: #00000000
  --height: auto
  --box-shadow: none !important
  --border: 0 !important
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-icon
  color: white
.title
  color: white
  font-size: 20px
</style>
